import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Box, Grommet, Footer, Text } from 'grommet';
import Header from '../header/header';
import { grommetTheme } from '../../theme/grommet';

// Keep this to load the fonts.
import styles from './layout.module.css';

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        return (
          <Grommet theme={grommetTheme} full>
            <Box background="dark-1" pad={{ bottom: 'xlarge' }}>
              <Header siteTitle={data.site.title} />
              {children}
            </Box>
            <Footer background={'light-1'} pad="medium" justify="center">
              <Box width="medium" margin={{ bottom: 'xlarge' }}>
                <Text textAlign="center">
                  Powered by Gatsby and Sanity. Copyright 2021.
                </Text>
              </Box>
            </Footer>
          </Grommet>
        );
      }}
    />
  );
};

export default Layout;
