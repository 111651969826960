import { deepMerge } from 'grommet/utils';

import { grommet } from 'grommet';

export const grommetTheme = deepMerge(grommet, {
  themeMode: 'dark',
  global: {
    font: {
      family: `-apple-system,
           BlinkMacSystemFont,
           "Segoe UI"`
    },
    size: {
      large: '1000px',
      medium: '738px',
      // For now, this is a hack to make layer minimum height to be zero.
      xxsmall: '0px'
    }
  },
  card: {
    container: {
      background: '#FFFFFF12',
      elevation: 'none',
      round: 'none'
    },
    footer: {
      background: '#FFFFFF06'
    }
  },
  anchor: {
    color: 'accent-2',
    hover: {
      textDecoration: 'none'
    }
  },
  layer: {
    size: 'none'
  },
  heading: {
    extend: ({ level, theme, margin }) => {
      const colors = {
        3: 'accent-2',
        2: 'accent-1'
      };
      const color = colors[level];
      return {
        color: '#fff',
        'font-family': 'Retro Gaming',
        'max-width': level === 1 ? 'inherit' : undefined,
        'margin-top': !margin ? '0px' : undefined,
        'margin-bottom':
          !margin && level === 3 ? theme.global.edgeSize.small : undefined
      };
    }
  },
  tab: {
    margin: {
      vertical: 'small',
      horizontal: 'small'
    }
  },
  text: {
    medium: {
      height: '32px'
    },
    large: {
      height: '36px'
    }
  },
  paragraph: {
    medium: {
      size: '18px',
      height: '32px',
      maxWidth: '738px'
    }
  }
});
