import React, { useState } from 'react';

import {
  Box,
  Heading,
  Anchor,
  Nav,
  Header as GrommetHeader,
  ResponsiveContext,
  Button,
  Layer,
  List
} from 'grommet';

import { Menu, Close } from 'grommet-icons';

const Header = ({ siteTitle }) => {
  const [menuShown, setMenuShown] = useState(false);

  const isBelowMedium = (responsive) =>
    responsive === 'medium' || responsive === 'small';

  return (
    <Box background="dark-1">
      <ResponsiveContext.Consumer>
        {(responsive) => (
          <>
            <GrommetHeader
              width="large"
              alignSelf="center"
              direction="row"
              alignContent="stretch"
              pad={isBelowMedium(responsive) ? 'medium' : 'none'}
            >
              <Heading level="1" margin={{ vertical: 'medium' }}>
                <Anchor href="/" color="accent-2">
                  {siteTitle}
                </Anchor>
              </Heading>

              <Button
                plain={false}
                icon={<Menu />}
                onClick={() => setMenuShown(true)}
              />
            </GrommetHeader>
            {menuShown && (
              <Layer
                position="right"
                modal
                full="vertical"
                onEsc={() => setMenuShown(false)}
                onClickOutside={() => setMenuShown(false)}
              >
                <Box fill="vertical" overflow="auto" width="small" pad="medium">
                  <Button
                    plain={false}
                    icon={<Close />}
                    onClick={() => setMenuShown(false)}
                  />
                  <Nav direction="column">
                    <Box pad="medium">
                      <Anchor size="large" href="/minecraft/">
                        Minecraft
                      </Anchor>
                    </Box>
                  </Nav>
                </Box>
              </Layer>
            )}
          </>
        )}
      </ResponsiveContext.Consumer>
    </Box>
  );
};

export default Header;
